import styled from "@emotion/styled";
import { Box } from "theme-ui";

export const ContainerBox = styled(Box)`
  position: relative;

  margin-right: auto;
  margin-left: auto;
  padding: 85px 65px 90px 65px;

  background-color: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
`;

export const OuterContainerBox = styled(Box)`
  margin-top: -40px;
  margin-bottom: 0px;
  padding: 0px 0px 55px 0px;
`;
