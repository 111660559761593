/** @jsx jsx */
import styled from "@emotion/styled";
import { jsx, Grid, Flex, Box, Image, Link, Heading, Text } from "theme-ui";

export default ({ description }) => {
  return (
    <Box>
      <Box sx={{ textAlign: "center", mx: "auto", mt: "3rem" }}>
        <StyledHeader>O NÁS</StyledHeader>
      </Box>

      <Box sx={{ px: [null, 2] }}>
        <Text
          sx={{ p: 1, textAlign: "center", mx: "auto" }}
          dangerouslySetInnerHTML={{ __html: description }}
        ></Text>
      </Box>
    </Box>
  );
};

const StyledHeader = styled(Heading)`
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
`;
