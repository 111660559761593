/** @jsx jsx */
import styled from "@emotion/styled";
import { jsx, Grid, Box, Image, Link, Heading, Text } from "theme-ui";
import { IconContext } from "react-icons";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

export default ({ contact }) => {
  const mapDirectionUrl = contact?.mapDirectionUrl;
  return (
    <Box>
      <Box sx={{ textAlign: "center", mx: "auto", mt: "5rem" }}>
        <StyledHeader>KDE NÁS NÁJDETE</StyledHeader>
      </Box>

      {!!contact?.map && (
        <Box
          sx={{
            maxWidth: "80%",
            height: "597px",
            mt: "2rem",
            mx: "auto",
            boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Link href={mapDirectionUrl}>
            <GoogleMapImage src={contact?.map?.url} />
          </Link>
        </Box>
      )}

      <Grid
        gap={[4, 2]}
        columns={["1fr", "1fr", "1fr 1fr", "1fr 1fr 1fr"]}
        sx={{ mx: [null, 6], mt: [4, 5] }}
      >
        <Box sx={{ width: "100%", px: [null, 2] }}>
          <Heading sx={{ variant: "text.contactHeading" }}>Adresa</Heading>
          <Text
            sx={{ variant: "text.contactText" }}
            dangerouslySetInnerHTML={{ __html: contact.address }}
          />
        </Box>

        <Box sx={{ width: "100%", px: [null, 2] }}>
          <Heading sx={{ variant: "text.contactHeading" }}>
            Otváracie hodiny
          </Heading>
          <Text
            sx={{ variant: "text.contactText" }}
            dangerouslySetInnerHTML={{ __html: contact.hours }}
          />
        </Box>

        <Box sx={{ width: "100%", px: [null, 2], mx: "auto", display: "grid" }}>
          <Heading sx={{ variant: "text.contactHeading" }}>Kontakt</Heading>
          <Text sx={{ variant: "text.contactText" }}>
            {contact.phone} <br />
            {contact.email}
          </Text>
        </Box>
      </Grid>

      <StyledBox sx={{ justifyContent: "center", mx: "auto", py: 4, px: 2 }}>
        <IconContext.Provider value={{ color: "black", size: "1.5rem" }}>
          <StyledLink href={contact?.facebook}>
            <FaFacebookF />
          </StyledLink>

          <StyledLink href={contact?.instagram}>
            <FaInstagram />
          </StyledLink>
        </IconContext.Provider>
      </StyledBox>
    </Box>
  );
};

const StyledHeader = styled(Heading)`
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
`;

const GoogleMapImage = styled(Image)`
  filter: brightness(100%) contrast(100%) saturate(100%) blur(0px)
    hue-rotate(0deg);
`;

const StyledBox = styled(Box)`
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;

  font-size: 23px;
`;
