/** @jsx jsx */
import { jsx, Box, Heading, Text } from "theme-ui";
import styled from "@emotion/styled";

import BackgroundImage from "gatsby-background-image";
import Logo from "./logo";

interface LandingProps {
  featuredImage: any;
  hero: HeroI;
}

interface HeroI {
  title: string;
  tagline: string;
  image: any;
  logo: any;
}

export default ({ featuredImage, hero }: LandingProps) => {
  // const image = featuredImage?.node?.sourceUrl;
  const { fluid } = featuredImage?.node?.localFile.childImageSharp;
  const logo = hero?.logo?.sourceUrl;

  return (
    <BackgroundHeroImage Tag="div" fluid={fluid}>
      <OverlayBox />
      <InnerBox
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo logo={logo} />
        <Heading
          sx={{
            marginTop: [4],
            marginBottom: [4, null],
            textAlign: "center",
            fontSize: [5, 6, 7],
            fontWeight: "black",
            textTransform: "uppercase",
          }}
        >
          {hero.title}
        </Heading>
        <Text sx={{ textAlign: "center", fontSize: [4, 5] }}>
          {hero.tagline}
        </Text>
      </InnerBox>
    </BackgroundHeroImage>
  );
};

const BackgroundHeroImage = styled(BackgroundImage)`
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerBox = styled(Box)`
  color: white;
  position: relative;
`;

const OverlayBox = styled(Box)`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: black;
  opacity: 0.5;
`;
