/** @jsx jsx */
import { Link } from "gatsby";
import styled from "@emotion/styled";
import {
  jsx,
  Grid,
  Box,
  Card,
  Heading,
  Text,
  Link as ThemeLink,
} from "theme-ui";
import { IconContext } from "react-icons";
import { FaCheck, FaArrowAltCircleRight } from "react-icons/fa";

import { OuterContainerBox } from "./containers";
import Image from "./image";

export default ({ services, list }) => {
  return (
    <OuterContainerBox>
      <Box
        sx={{
          maxWidth: 1140,
          mx: "auto",
          pt: ["85px"],
          pb: ["90px"],
          px: [2, 5],
          boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.1)",
          position: "relative",
          backgroundColor: "#ffffff",
        }}
      >
        <Grid gap={2} columns={["1fr", null, "2fr 3fr"]} sx={{ mx: -2 }}>
          <Box sx={{ px: [null, 2], mb: [3, null], pl: [4, null] }}>
            <Card sx={{ mx: "auto" }}>
              <Heading sx={{ fontSize: 4 }}>{services.title}</Heading>
              <Text
                sx={{
                  fontSize: 2,
                }}
              >
                {services.description}
              </Text>

              <Separator />

              <ServicesList services={list} />

              <Box>
                <ThemeLink
                  as={Link}
                  to="/realizacie"
                  sx={{
                    variant: "buttons.pill",
                    width: "256px",
                    display: "block",
                    height: "55px",
                    p: "11px 15px",
                    //
                    textDecoration: "none",
                    color: "#ffffff",
                    bg: "#011c3a",
                    ":hover": {
                      bg: "#ffffff",
                      color: "#011c3a",
                      borderColor: "#011c3as",
                    },
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "28px 28px 28px 28px",
                    fontSize: [3, 2, 2],
                  }}
                >
                  Pozrieť realizácie
                  {/* Naše realizácie */}
                  <span sx={{ float: "right" }}>
                    <IconContext.Provider value={{ size: "1rem" }}>
                      <StyledFaIcon />
                    </IconContext.Provider>
                  </span>
                </ThemeLink>
              </Box>

              <Box sx={{ mt: "40px", mb: "40px" }}>
                <ThemeLink
                  as={Link}
                  to="/matrace"
                  sx={{
                    variant: "buttons.pill",
                    width: "256px",
                    display: "block",
                    height: "55px",
                    p: "11px 15px",
                    textDecoration: "none",
                    color: "#011c3a",
                    bg: "ffffff",
                    ":hover": {
                      color: "#ffffff",
                      bg: "#011c3a",
                    },
                    borderColor: "#011c3a",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "28px 28px 28px 28px",
                    fontSize: [3, 2, 2],
                  }}
                >
                  Matrace Drevočal
                  <span sx={{ float: "right" }}>
                    <IconContext.Provider value={{ size: "1rem" }}>
                      <StyledFaIcon />
                    </IconContext.Provider>
                  </span>
                </ThemeLink>
              </Box>

              <Box>
                <ThemeLink
                  as={Link}
                  to="/produkty"
                  sx={{
                    variant: "buttons.pill",
                    width: "256px",
                    display: "block",
                    height: "55px",
                    p: "11px 15px",
                    textDecoration: "none",
                    color: "#011c3a",
                    bg: "ffffff",
                    ":hover": {
                      color: "#ffffff",
                      bg: "#011c3a",
                    },
                    borderColor: "#011c3a",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "28px 28px 28px 28px",
                    fontSize: [3, 2, 2],
                  }}
                >
                  {/* Pozrieť produkty */}
                  Ďalšie produkty
                  <span sx={{ float: "right" }}>
                    <IconContext.Provider value={{ size: "1rem" }}>
                      <StyledFaIcon />
                    </IconContext.Provider>
                  </span>
                </ThemeLink>
              </Box>
            </Card>
          </Box>

          <Box sx={{ px: 2, mt: [3, null] }}>
            <Card sx={{ mx: "auto" }}>
              <Image url={services?.image?.sourceUrl} />
              {/* <Image src={services?.image?.localFile?.url} /> */}
              {/* <Image src={services.image.localFile.childImageSharp.fluid} /> */}
            </Card>
          </Box>
        </Grid>
      </Box>
    </OuterContainerBox>
  );
};

const StyledFaIcon = styled(FaArrowAltCircleRight)`
  margin-left: 5px;
`;

const Separator = styled.div`
  width: 100px;
  height: 4px;
  margin-top: 20px;
  margin-bottom: 38px;
  background: linear-gradient(90deg, #011c3a 0%, #011c3a 100%);
`;

const ServicesList = ({ services }) => (
  <IconContext.Provider value={{ size: "1rem" }}>
    <StyledUl>
      {services &&
        services.map((s, i) => (
          <li key={i}>
            <span>
              {/* <FontAwesomeIcon icon={faCheck} size="1x" /> */}
              <FaCheck />
            </span>
            <StyledTextSpan>{s}</StyledTextSpan>
          </li>
        ))}
    </StyledUl>
  </IconContext.Provider>
);

const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 38px;
`;

const StyledTextSpan = styled.span`
  align-self: center;
  padding-left: 5px;
  color: #54595f;
`;
