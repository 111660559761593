import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box, Heading } from "theme-ui";
import ResizeObserver from "react-resize-observer";

import { OuterContainerBox } from "./containers";
import { Slideshow } from "./slideshow";

export default ({ partners }) => {
  const [width, setWidth] = useState(null);

  return (
    <OuterContainerBox>
      <ResizeObserver
        onResize={rect => {
          // console.log("Resized. New bounds:", rect.width, "x", rect.height);
          // console.log("Resized. New bounds:", window.innerWidth, "x", window.innerHeight);
          setWidth(rect.width);
        }}
        // onPosition={rect => {
        //   console.log("Moved. New position:", rect.left, "x", rect.top);
        // }}
      />
      <Box sx={{ textAlign: "center", mx: "auto", mt: "5rem" }}>
        <StyledHeader>NAŠI PARTNERI</StyledHeader>
      </Box>
      <Slideshow
        images={partners.map(x => ({
          fixed: x?.localFile.childImageSharp.fixed,
        }))}
        width={width}
      />
    </OuterContainerBox>
  );
};

const StyledHeader = styled(Heading)`
  color: #333333;
  margin: 0px 0px 0px 0px;
  font-family: "Lato", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
`;
