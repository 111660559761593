import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Landing from "../components/landing";
import ServicesCard from "../components/services";
// import OfferForm from "../components/offer";
import AboutUs from "../components/about-us";
import Partners from "../components/partners";
import ContactInfo from "../components/contact";

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data?.wpPage?.seo}>
      <Landing
        featuredImage={data?.wpPage?.featuredImage}
        hero={data?.wpPage?.homePageAcf?.hero || {}}
      />
      <ServicesCard
        services={data?.wpPage?.homePageAcf?.services || []}
        list={
          data?.allWpCategory?.edges
            ?.filter(e => e.node.name !== "Nezaradené")
            .map(e => e.node.name)
        }
      />
      {/* <OfferForm /> */}
      {data?.wpPage?.homePageAcf?.aboutUs?.description && (
        <AboutUs
          description={data?.wpPage?.homePageAcf?.aboutUs?.description}
        />
      )}
      <Partners partners={data?.wpPage?.homePageAcf?.partnerLogos || []} />
      <ContactInfo contact={data?.wpPage?.homePageAcf?.contact} />
    </Layout>
  );
};

const query = graphql`
  query IndexQuery {
    allWpPost {
      edges {
        node {
          id
          title
          excerpt
        }
      }
    }
    allWpCategory {
      edges {
        node {
          name
        }
      }
    }
    products: allWpProduct(limit: 1000) {
      edges {
        node {
          id
          name
        }
      }
    }
    wpPage(slug: { eq: "uvodna-stranka" }) {
      seo {
        title
        metaDesc
      }
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      homePageAcf {
        hero {
          title
          tagline
          logo {
            sourceUrl
            # localFile {
            # childImageSharp {
            #   fluid(maxWidth: 10) {
            #     ...GatsbyImageSharpFluid
            #   }
            # }
            # }
          }
        }
        services {
          title
          description
          image {
            sourceUrl
          }
        }
        partnerLogos {
          localFile {
            childImageSharp {
              fixed(width: 206) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        aboutUs {
          description
        }
        contact {
          address
          hours
          phone
          email
          map {
            city
            country
            countryShort
            latitude
            longitude
            placeId
            postCode
            state
            stateShort
            streetAddress
            streetName
            streetNumber
            zoom
          }
          facebook
          instagram
        }
      }
    }
  }
`;

export default IndexPage;
